// import React from "react";
import PropTypes from "prop-types";

export const InlineEdit = ({ value, original, endpoint }) => {
  // TODO: turn into inline edit in SC-8123
  return value;
};

InlineEdit.propTypes = {
  value: PropTypes.any,
  original: PropTypes.object,
  endpoint: PropTypes.string,
};