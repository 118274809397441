import React, { useEffect } from "react";
import { AuctionPageLayout } from "../components";
import { analyticsPageView } from "../utils/google-analytics";

export const AuctionPage = () => {

  useEffect(() => {
    analyticsPageView();
  }, []);

  return <AuctionPageLayout />
};;