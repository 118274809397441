import React from "react";
import PropTypes from "prop-types";
import { MetaCard } from "@spreetail/meta-components";

import { KeyValueCard } from "../key-value-card";
import { formatDateOrFallback } from "../../utils";

export const AuctionOverview = ({ endTime, spreetailPONumber, startTime, status, vendor }) => {

  const values = [
    { title: "Spreetail PO #", value: spreetailPONumber || "-", id: "spreetailPONumber" },
    { title: "Vendor", value: vendor, id: "vendor" },
    { title: "Start Time", value: formatDateOrFallback(startTime), id: "startTime" },
    { title: "End Time", value: formatDateOrFallback(endTime), id: "endTime" },
  ];

  return (
    <div className="overview">
      <MetaCard title="Auction Overview" className="no-padding" data-testid="auction-overview">
        <div className="auction-overview overview flex-row">
          {values.map(({ id, ...values }) => <KeyValueCard key={id} {...values} />)}
        </div>
      </MetaCard>
    </div>
  );
};

AuctionOverview.propTypes = {
  endTime: PropTypes.string,
  spreetailPONumber: PropTypes.string,
  startTime: PropTypes.string,
  status: PropTypes.string,
  vendor: PropTypes.string,
};

export default AuctionOverview;
