import { biddingExternalInstance } from "./instance";

export const getAuction = async (key, { auctionId, brokerToken }) => {
  const { data } = await biddingExternalInstance.get(`/auctions/${auctionId}/${brokerToken}`);
  return data;
};

export const createBid = async (bid) => {
  const { data } = await biddingExternalInstance.post(`/bids`, bid);
  return data;
};

export const editBid = async ({ bid, bidId }) => {
  const { data } = await biddingExternalInstance.put(`/bids/${bidId}`, bid);
  return data;
};

export const deleteBid = async ({ bidId, brokerToken }) => {
  const { data } = await biddingExternalInstance.delete(`/bids/${bidId}`, { data: { brokerToken } });
  return data;
};