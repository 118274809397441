import React, { useContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import { actions, reducer, initialState } from "./reducer";
import { MetaModal } from "@spreetail/meta-components";
// import { useMediaQuery } from "../../hooks";

const overlayStyles = () => ({
  zIndex: 700
});

const defaultContext = {
  open: () => null,
  close: () => null,
  setBlocking: () => null,
  setPayload: () => null,
  setContents: () => null,
  setCallback: () => null,
  setStyle: () => null
};

export const ModalContext = React.createContext(defaultContext);
export const useModalContext = () => useContext(ModalContext);

const getContextValue = (state, dispatch) => ({
  isOpen: state.isOpen,
  open: (contents, blocking, style) =>
    dispatch(actions.open(contents, blocking, style)),
  close: payload => {
    if (state.callback) state.callback(payload || state.payload);
    dispatch(actions.close());
  },
  setPayload: payload => dispatch(actions.setPayload(payload)),
  setContents: contents => dispatch(actions.setContents(contents)),
  setCallback: callback => dispatch(actions.setCallback(callback)),
  setBlocking: blocking => dispatch(actions.setBlocking(blocking)),
  setStyle: style => dispatch(actions.setStyle(style))
});

export const ModalContextProvider = ({ children }) => {
  const reducerValue = useReducer(reducer, initialState);
  const [state] = reducerValue;
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const context = useMemo(() => getContextValue(...reducerValue), [reducerValue]);
  // const smallScreen = useMediaQuery("(max-width: 900px)");

  const contentStyles = (styles) => ({
    width: "clamp(300px,500px, 90vw)",
    marginLeft: "auto",
    marginRight: "auto",
    ...styles
  });

  return (
    <ModalContext.Provider value={context}>
      {children}
      <MetaModal
        isOpen={state.isOpen}
        onRequestClose={() => (!state.blocking ? context.close() : null)}
        style={{
          content: contentStyles(state.style),
          overlay: overlayStyles()
        }}
      >
        {state.contents}
      </MetaModal>
    </ModalContext.Provider>
  );
};

ModalContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
