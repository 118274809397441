import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const KeyValueCard = ({ children, title, value, id, isEditable = false }) => {
  const classes = classNames("overview-card", { "is-editable": isEditable });

  return (
    <div className={classes} data-testid={id}>
      <h5 className="overview-card__key" data-testid="key">{title}</h5>
      <span className="overview-card__value" data-testid="value">
        {children ? children : value}
      </span>
    </div>
  )
};

KeyValueCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  id: PropTypes.string,
  isEditable: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
};
