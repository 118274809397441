import React from "react";
import { MetaPage, MetaActionBar } from "@spreetail/meta-components";

import { externalRoutes } from "./routes";
import { Switch } from "react-router-dom";
import logo from './logo.svg';

const ExternalApp = () => {

  return (
    <div className="meta-app bidding-external" data-testid="bidding-external">
      <MetaPage actionBarContent={
        <MetaActionBar>
          <img src={logo} alt="Spreetail Logo" className="logo" />
          <span>Freight Bidding</span>
        </MetaActionBar>
      }>
        <Switch>
          {externalRoutes}
        </Switch>
      </MetaPage>
    </div>
  );
};


export default ExternalApp;
