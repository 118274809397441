import { DateTime } from "luxon";
import currency from "currency.js";

export const formatTextOrFallback = (value, fallback = "-") => value ? value : fallback;

export const formatDate = (value, format = "f") => DateTime.fromISO(value).toFormat(format);
export const formatDateOrFallback = (value, format, fallback = "-") => value ? formatDate(value, format) : fallback;

export const formatCurrencyToDecimal = (value) => currency(value).value;
export const formatCurrencyToString = (value) => value ? currency(value).format() : value;
export const formatCurrencyOrFallback = (value, fallback = "-") => value ? formatCurrencyToString(value) : fallback;

export const formatPercentage = (value) => `${value * 100}%`;
export const formatPercentageOrFallback = (value, fallback = "-") => value ? formatPercentage(value) : fallback;

export const formatBoolean = (value) => value !== null ? value.toString() : value;

export const formatAddressCityState = (value) => value.match(/([\w,]+), ([A-Z]{2})/)?.[0];
export const formatAddressCityStateOrFallback = (value, fallback = "-") => value ? formatAddressCityState(value) : fallback;

export const formatPhone = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '', match[2], '-', match[3], '-', match[4]].join('');
  }
  return null;
}