import React from "react";
import { Route } from "react-router-dom";
import { HomePage, AuctionPage } from "../domain";

const createRoute = ({ path, component, exact = true, RouteComponent = Route, ...otherProps }) =>
  <RouteComponent
    path={path}
    component={component}
    key={path || "*"}
    exact={exact}
    {...otherProps}
  />;

export const externalRoutes = [
  createRoute({
    path: "/auction/:auctionId/:brokerToken",
    component: AuctionPage,
  }),
  createRoute({
    component: HomePage,
  })
];
