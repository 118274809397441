export const initialState = {
  isOpen: false,
  contents: null,
  callback: null,
  payload: null,
  blocking: false,
  style: {}
};

export const OPEN = "modalContext/OPEN";
export const CLOSE = "modalContext/CLOSE";
export const SET_CONTENTS = "modalContext/SET_CONTENTS";
export const SET_CALLBACK = "modalContext/SET_CALLBACK";
export const SET_PAYLOAD = "modalContext/SET_PAYLOAD";
export const SET_BLOCKING = "modalContext/SET_BLOCKING";
export const SET_STYLE = "modalContext/SET_STYLE";

export const actions = {
  open: (contents, blocking, style) => ({
    type: OPEN,
    contents,
    blocking,
    style
  }),
  close: () => ({ type: CLOSE }),
  setPayload: payload => ({ type: SET_PAYLOAD, payload }),
  setContents: contents => ({ type: SET_CONTENTS, contents }),
  setCallback: callback => ({ type: SET_CALLBACK, callback }),
  setBlocking: blocking => ({ type: SET_BLOCKING, blocking }),
  setStyle: style => ({ type: SET_STYLE, style })
};

const core = {
  [OPEN]: (state, action) => ({
    ...state,
    isOpen: true,
    contents:
      typeof action.contents === "undefined" ? state.contents : action.contents,
    blocking:
      typeof action.blocking === "undefined" ? state.blocking : action.blocking,
    style: typeof action.style === "undefined" ? {} : action.style
  }),
  [CLOSE]: state => ({
    ...state,
    contents: null,
    isOpen: false
  }),
  [SET_PAYLOAD]: (state, action) => ({
    ...state,
    payload: action.payload
  }),
  [SET_CONTENTS]: (state, action) => ({
    ...state,
    callback: null,
    payload: null,
    contents: action.contents,
    blocking: false,
    style: {}
  }),
  [SET_CALLBACK]: (state, action) => ({ ...state, callback: action.callback }),
  [SET_BLOCKING]: (state, action) => ({ ...state, blocking: action.blocking }),
  [SET_STYLE]: (state, action) => ({ ...state, style: action.style })
};

export const reducer = (state = initialState, action) => {
  const coreFunction = core[action.type];
  return coreFunction ? coreFunction(state, action) : state;
};
