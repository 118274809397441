import React from "react";
import PropTypes from "prop-types";
import { MetaTable, MetaIcon, MetaCard } from "@spreetail/meta-components";
import { Check } from "@spreetail/meta-icons";
import { formatPhone } from "../../utils";

export const PickupContactsDetails = ({ pickupContacts }) => {
  const data = pickupContacts ? pickupContacts.map(({ ...contact }) => { return { name: contact.name, title: contact.title, email: contact.email, phone: formatPhone(contact.phone), primary: contact.isPrimary }; }
  ) : [];
  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Title / Description",
      accessor: "title",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Primary",
      accessor: "primary",
      Cell: ({ value }) => <MetaIcon src={value ? Check : null} />
    }
  ];


  return (
    <MetaCard title="Contacts" className="routing-details-pickup-contacts no-padding">
      <MetaTable
        data={data}
        columns={columns}
        sortable={false}
        resizable={false}
        showPaginationBottom={false}
        noDataText="No Pick-Up contacts"
        getProps={() => ({ "data-testid": "contacts-table" })}
      />
    </MetaCard>

  );
};

PickupContactsDetails.propTypes = {
  pickupContacts: PropTypes.array
};

export default PickupContactsDetails;