import React, { Suspense } from "react";
import { MetaSpinner } from "@spreetail/meta-components";
import { initializeGA } from "./utils";
import ExternalApp from "./app-external";

initializeGA();
const App = () => (
  <Suspense fallback={<MetaSpinner />}>
    <ExternalApp />
  </Suspense>
);
export default App;
