import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-form";
import { useMutation, queryCache } from "react-query";
import { MetaButton, MetaToast, MetaSelect } from "@spreetail/meta-components";
import { X } from "@spreetail/meta-icons";

import { useModalContext } from "../../contexts/modal-context";
import { InputField, CurrencyMaskInputField } from "../input-field";
import { validateCarrier, validateCostQuote, validateTransitTime, formatCurrencyToDecimal } from "../../utils";
import { createBid, editBid } from "../../api/endpoints";

export const BidModal = ({ bid, auctionId, brokerToken, isNew = true }) => {
  const typeText = isNew ? "Submit" : "Update";
  const modalContext = useModalContext();
  const close = () => modalContext.close();

  const [mutate] = useMutation(isNew ? createBid : editBid, {
    onError: ({ message }) => {
      MetaToast.error(message || "Unknown error.", { closeButton: true })
    },
    onSuccess: () => {
      queryCache.invalidateQueries("auction", { auctionId, brokerToken, internal: false }); // re-requests auction for updates
      close();
      MetaToast.success(`Bid ${isNew ? "created" : "updated"}.`, { closeButton: true })
    }
  });

  const onSubmit = async (values) => {
    const bidToSubmit = { ...values, costQuote: formatCurrencyToDecimal(values.costQuote), transitTime: values.transitTime.value, brokerToken };
    return isNew ? await mutate({ ...bidToSubmit }) : await mutate({ bid: bidToSubmit, bidId: bid?.bidId });
  };

  const { Form, meta: { isSubmitting, canSubmit, isTouched } } = useForm({
    // debugForm: true, // (uncomment to see inline form values on page)
    defaultValues: React.useMemo(
      () => ({
        costQuote: bid?.costQuote,
        transitTime: bid?.transitTime ? { label: parseInt(bid?.transitTime, 10), value: parseInt(bid?.transitTime, 10) } : null,
        carrier: bid?.carrier || ""
      }), [bid]
    ),
    onSubmit
  });

  return (
    <div className="bidding-modal-bid-edit" data-testid="modal-bid-edit">
      <Form>
        <div className="meta-modal__header">
          <h3 className="meta-modal__header-title">{`${typeText} Bid`}</h3>
          <MetaButton variant="no-background" icon={{ src: X }} onClick={close} type="button" />
        </div>

        <div className="meta-modal__content">
          <CurrencyMaskInputField field="costQuote" label="Cost Quote" validate={validateCostQuote} data-testid="bid-modal-costQuote-input" />
          <InputField field="transitTime"
            name="transitTime"
            label="Transit Time (Days)"
            Input={MetaSelect}
            options={[1, 2, 3, 4, 5, 6, 7].map(value => ({ label: value, value }))}
            validate={validateTransitTime}
            helperContent="Maximum 7 days" 
            data-testid="bid-modal-transitTime-input"
          />
          <InputField field="carrier" label="Carrier" validate={validateCarrier} data-testid="bid-modal-carrier-input" />
        </div>

        <div className="meta-modal__footer">
          <MetaButton
            key="cancel"
            type="button"
            onClick={close}
            text="Cancel"
            disabled={isSubmitting}
            variant="outline"
            data-testid="modal-bid-edit-button-cancel"
          />
          <MetaButton
            key="confirm"
            variant="primary"
            text={typeText}
            textOnLoading={false}
            type="submit"
            disabled={!isTouched || !canSubmit || isSubmitting}
            data-testid={`modal-bid-edit-button-${typeText}`}
          />
        </div>
      </Form>
    </div>
  );
}

BidModal.propTypes = {
  auctionId: PropTypes.string,
  bid: PropTypes.shape({
    bidId: PropTypes.number,
    broker: PropTypes.string,
    costQuote: PropTypes.number,
    transitTime: PropTypes.string,
    carrier: PropTypes.string,
  }),
  brokerToken: PropTypes.string,
  isNew: PropTypes.bool,
};