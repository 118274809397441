import React from "react";
import PropTypes from "prop-types";
import { MetaCard } from "@spreetail/meta-components";
import { KeyValueCard } from "../key-value-card";
import { formatDateOrFallback, formatAddressCityStateOrFallback } from "../../utils";

export const Logistics = ({ pickUpDate, origin, destination }) => {

  const values = [
    { title: "Pick-Up Date", value: formatDateOrFallback(pickUpDate), id: "pickUpDate" },
    { title: "Origin", value: formatAddressCityStateOrFallback(origin), id: "origin" },
    { title: "Destination", value: destination, id: "destination" },
  ];

  return (
    <MetaCard title="Logistics Overview" className="routing-details-logistics no-padding">
      <div className="flex-row">
        {values.map(({ id, ...values }) => <KeyValueCard key={id} {...values} />)}
      </div>
    </MetaCard>
  );
};

Logistics.propTypes = {
  pickUpDate: PropTypes.string,
  origin: PropTypes.string,
  destination: PropTypes.string,
};