import React from "react";
import NumberFormat from "react-number-format";
import { useField, splitFormProps } from "react-form";
import classNames from "classnames";

export const CurrencyMaskInputField = React.forwardRef((props, ref) => {
  const [field, fieldOptions, rest] = splitFormProps(props); // splitFormProps to get form-specific props
  const { meta: { error }, getInputProps } = useField(field, fieldOptions); // useField hook with a field & field options to access field state
  const { defaultValue, ...inputProps } = getInputProps({ ref, ...rest });

  const handleFocus = e => {
    e.target.placeholder = "";
    if (inputProps?.onFocus && typeof inputProps?.onFocus === "function") inputProps.onFocus(e);
  };

  const handleBlur = e => {
    e.target.placeholder = inputProps?.label;
    if (inputProps?.onBlur && typeof inputProps?.onBlur === "function") inputProps.onBlur();
  };

  return (
    <div className={classNames("meta-input", inputProps?.className, { "input-invalid input-invalid__error": error, "bottom-buffer": !error && !rest.helperContent })}>
      <NumberFormat {...inputProps}
        {...rest}
        className="meta-input__input"
        prefix="$"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        placeholder={inputProps?.label}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <label className="meta-input__label" htmlFor={inputProps?.id || inputProps?.label}>
        {inputProps?.id || inputProps?.label}
      </label>

      <ErrorMessage isValid={!error} errorMessage={error} helperContent={inputProps?.helperContent} />
    </div>
  );
});

const ErrorMessage = ({ isValid = true, errorMessage = "", helperContent, className }) => {
  const classes = classNames("meta-validation", className, { error: !isValid });

  return ((!isValid || helperContent)
    ? <div className={classes}>
      {helperContent && typeof helperContent === "string" ? <p className="micro">{helperContent}</p> : helperContent}
      {!isValid && <sub className="messaging-text"> {errorMessage} </sub>}
    </div>
    : null
  );
};