import React from "react";
import PropTypes from "prop-types";
import { useMutation, queryCache } from "react-query";
import { MetaButton, MetaToast } from "@spreetail/meta-components";
import { X } from "@spreetail/meta-icons";

import { useModalContext } from "../../contexts/modal-context";
import { deleteBid } from "../../api/endpoints";

export const DeleteBidModal = ({ bidId, auctionId, brokerToken }) => {
  const modalContext = useModalContext();
  const close = () => modalContext.close();

  const [mutate, { isLoading }] = useMutation(deleteBid, {
    onError: ({ message }) => {
      MetaToast.error(message || "Error deleting bid.", { closeButton: true });
    },
    onSuccess: () => {
      queryCache.invalidateQueries("auction", { auctionId, brokerToken, internal: false }); // re-requests auction for updates
      close();
      MetaToast.success("Bid deleted.", { closeButton: true });
    }
  });

  return (
    <div className="bidding-modal-bid-delete" data-testid="modal-bid-delete">
      <div className="meta-modal__header">
        <h3 className="meta-modal__header-title">Delete Bid</h3>
        <MetaButton variant="no-background" icon={{ src: X }} onClick={close} />
      </div>

      <div className="meta-modal__content">
        <p>Are you sure you want to remove this bid? This action can’t be reversed.</p>
      </div>

      <div className="meta-modal__footer">
        <MetaButton
          data-testid="modal-bid-delete-button-cancel"
          onClick={close}
          text="Cancel"
          disabled={isLoading}
          variant="outline"
        />
        <MetaButton
          data-testid="modal-bid-delete-button-delete"
          variant="critical"
          text="Delete"
          onClick={() => mutate({ bidId, brokerToken })}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}

DeleteBidModal.propTypes = {
  auctionId: PropTypes.string,
  bidId: PropTypes.number,
  brokerToken: PropTypes.string,
  isNew: PropTypes.bool,
};