import currency from "currency.js";

export const validateCostQuote = (value) => {
  const decimal = currency(value);
  if (!value || decimal < 1) return "A Cost Quote is required.";
  if (isNaN(decimal.value)) return "Invalid Cost Quote.";
  return false;
};

export const validateTransitTime = (value) => {
  if (!value) return "Transit Time is required.";
  if (value > 7) return "Maximum: 7 days";
  return false;
};

export const validateCarrier = (value) => {
  if (!value) return "A carrier is required.";
  return false;
};

export const validateEmail = (value) => {
  if (!value) return "Email is reqiured.";
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(value)) return "Please enter a valid email.";
  return false;
};

export const validateBrokerName = (value) => {
  if (!value) return "A Broker Name is required.";
  return false;
};

export const validateText = (value) => {
  if (!value) return "Value is required.";
  return false;
};