import React from "react";
import PropTypes from "prop-types";
import { useParams, Redirect } from "react-router-dom";
import { useQuery } from "react-query";
import { MetaSpinner } from "@spreetail/meta-components";

import { getAuction } from "../../api";
import { AuctionOverview } from "../auction-overview";
import { RoutingDetails } from "../routing-details";
import { BidsBroker } from "../bids-broker";

export const AuctionPageLayout = ({ setAuctionStatus }) => {
  const { auctionId, brokerToken } = useParams();

  const { isLoading, data, error } = useQuery(["auction", { auctionId, brokerToken }], getAuction, {
    retry: 1,
    refetchOnWindowFocus: false,
    onSuccess: ({ status }) => setAuctionStatus && setAuctionStatus(status) // pass status to parent for header
  });

  if (isLoading) return <MetaSpinner />;
  if (error)
    return <Redirect to="/" />;

  return (data &&
    <div className="auction-layout">
      <AuctionOverview {...data} />
      <BidsBroker bids={data.bids} auctionId={auctionId} brokerToken={brokerToken} />
      <RoutingDetails {...data.routingDetails} />
    </div>
  );
};

AuctionPageLayout.propTypes = {
  setAuctionStatus: PropTypes.func,
};