import React from "react";
import PropTypes from "prop-types";
import { MetaCard, MetaExpandablePanel, MetaTable } from "@spreetail/meta-components";

import { KeyValueCard } from "../key-value-card";
import { formatCurrencyOrFallback, formatTextOrFallback } from "../../utils";

export const LoadDetails = ({ size, value, commodity, nmfc, cartonCount, palletCount, weight, cubicFeet, pallets }) => {

  const values = [
    { title: "Size", value: size || "-", id: "size", isEditable: true },
    { title: "Value", value: formatCurrencyOrFallback(value), id: "value", isEditable: true },
    { title: "Commodity", value: formatTextOrFallback(commodity), id: "commodity", isEditable: true },
    { title: "NMFC", value: nmfc || "-", id: "nmfc", isEditable: true },
    { title: "Case / Carton Count", value: cartonCount || "-", id: "caseCartonCount", isEditable: true },
    { title: "Palletized", value: palletCount > 0 ? "Yes" : "No", id: "Palletized", isEditable: true },
    { title: "Pallet Count", value: palletCount, id: "palletCount", show: pallets?.length > 0, },
    { title: "Weight (LBS)", value: weight, id: "weight" },
    { title: "Cubic Feet", value: cubicFeet, id: "cubicFeet" }
  ];

  return (
    <>
      <MetaCard title="Load Details" className="routing-details-load no-padding" data-testid="routing-details-load">
        <div className="flex-row">
          {values.map(({ id, show = true, ...values }) => show && <KeyValueCard key={id} id={`load-${id}`} {...values} />)}
        </div>
      </MetaCard>
      {pallets?.length > 0 &&
        <MetaExpandablePanel title="Pallets" className="meta-card routing-details-pallets no-padding" data-testid="routing-details-pallets" isExpanded>
          <MetaTable
            data={pallets}
            columns={[
              { Header: "Weight (lbs)", accessor: "weight" },
              { Header: "Freight Class", accessor: "freightClass" },
              { Header: "Stackable", accessor: "isStackable", Cell: ({ value }) => value === true ? "Yes" : "No" },
              { Header: "Length (in)", accessor: "length" },
              { Header: "Width (in)", accessor: "width" },
              { Header: "Height (in)", accessor: "height" },
              { Header: "Cubic feet", accessor: "cubicFeet" },
            ]}
            sortable={false}
            resizable={false}
            showPaginationBottom={false}
            getProps={() => ({ "data-testid": "pallets-table" })}
          />
        </MetaExpandablePanel>
      }
    </>
  );
};

LoadDetails.propTypes = {
  size: PropTypes.string,
  value: PropTypes.number,
  commodity: PropTypes.string,
  nmfc: PropTypes.string,
  cartonCount: PropTypes.number,
  palletCount: PropTypes.number,
  weight: PropTypes.number,
  cubicFeet: PropTypes.number
};

export default LoadDetails;