import React from "react";
import ReactDOM from "react-dom";
import App from "./app-base";
import ContextProvider from "./contexts";
import registerServiceWorker from "./registerServiceWorker";
import "./styles/all-styles.scss";
import "./styles/styles-external.scss";

ReactDOM.render(
  <ContextProvider>
    <App />
  </ContextProvider>,
  document.getElementById("root"));

registerServiceWorker();

