import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MetaToastContainer, MetaToast } from "@spreetail/meta-components";

export const ToastContext = React.createContext(MetaToast);
export const useToastContext = () => useContext(ToastContext);

export const ToastContextProvider = props => {
  const { children } = props;

  return (
    <ToastContext.Provider value={MetaToast}>
      {children}
      <MetaToastContainer />
    </ToastContext.Provider>
  );
};

ToastContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
