import ReactGA from "react-ga";
import { getJwtProperty } from "spreetail-cookie-util";

const PRODUCT_NAME = "Bidding";
const MASTER_NAME = "Master";
const MASTER_GA_ID = "UA-76254720-22";

export const initializeGA = () => {
  const userId = getJwtProperty("usrid");

  ReactGA.initialize(
    [
      {
        trackingId: "UA-76254720-33",
        gaOptions: {
          userId,
          siteSpeedSampleRate: 100,
          name: PRODUCT_NAME,
        },
        debug: false,
      },
      {
        trackingId: MASTER_GA_ID,
        gaOptions: {
          userId,
          siteSpeedSampleRate: 100,
          name: MASTER_NAME,
        },
        debug: false,
      },
    ],
    { debug: false } // make sure this is false in prod
  );
  ReactGA.set({ userId }, [PRODUCT_NAME, MASTER_NAME]);
};

export const analyticsPageView = (overrideLocation = "") => {
  const location = overrideLocation || window.location.pathname;

  ReactGA.pageview(location, [PRODUCT_NAME, MASTER_NAME]);
};

export const analyticsEvent = (category, action, label, value = 0) => {
  ReactGA.event(
    {
      category,
      action,
      label,
      value,
    },
    [PRODUCT_NAME, MASTER_NAME]
  );
};