import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ReactQueryDevtools } from "react-query-devtools";
import { QueryParamProvider } from "use-query-params";

import { ToastContextProvider } from "./toast-context";
import { ModalContextProvider } from "./modal-context";
import { ErrorDisplay } from "../components";

const ContextProvider = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorDisplay}
    >
      <Router>
        <Switch>
          <QueryParamProvider ReactRouterRoute={Route}>
            <ModalContextProvider>
              <ToastContextProvider >
                {/* <DrawerContextProvider> */}
                {children}
                {/* </DrawerContextProvider> */}
              </ToastContextProvider>
            </ModalContextProvider>

            {process.env.NODE_ENV === "development" &&
              <Route exact path="/logout" component={() => {
                window.location = "http://m2dev.spreetail.org/Controls/Master/Logout.aspx";
                return null;
              }} />
            }
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryParamProvider>
        </Switch>
      </Router>
    </ErrorBoundary>
  )
};

ContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ContextProvider;
export * from "./modal-context";
export * from "./toast-context";