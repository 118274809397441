import React from "react";
import { useField, splitFormProps } from "react-form";
import { MetaInput } from "@spreetail/meta-components";

export const InputField = React.forwardRef((props, ref) => {
  const [field, fieldOptions, rest] = splitFormProps(props); // splitFormProps to get form-specific props
  const { meta: { error }, getInputProps, setValue } = useField(field, fieldOptions); // useField hook with a field & field options to access field state
  const inputProps = getInputProps({ ref, ...rest });
  const Input = rest.Input || MetaInput;

  const selectOnChange = ({ value, label }) => {
    setValue({ target: { value } });
    inputProps.onChange({ target: { value: { label: label ? label : value, value } } });
  };

  const onChange = rest.Input?.name === "MetaSelect" ? selectOnChange : inputProps.onChange;

  return (
    <Input {...inputProps}
      {...rest}
      onChange={onChange}
      isValid={!error}
      errorMessage={error}
      helperContent={error ? null : rest.helperContent} 
      className={!error && !rest.helperContent ? "bottom-buffer" : ""} />
  );
});

export * from "./input-field-currency";