import React from "react";
import { MetaCard } from "@spreetail/meta-components";

export const HomePage = () => {
  return (
    <MetaCard title="Spreetail Freight Bidding" data-testid="external-homepage">
      <p>Oops! Looks like your link didn't take you to the right place. Please try to access the page from your email again.</p>
      <p>If the error persists, please reach out to someone at Spreetail.</p>
    </MetaCard>
  )
};
