import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { MetaCard, MetaTable, MetaButton } from "@spreetail/meta-components";
import { Trash, EditSquare } from "@spreetail/meta-icons";

import { formatCurrencyOrFallback } from "../../utils";
import { useModalContext } from "../../contexts/modal-context";
import { BidModal } from "../modal-bid-new-edit/modal-bid-new-edit";
import { DeleteBidModal } from "../modal-bid-delete/modal-bid-delete";

export const BidsBroker = ({ bids, auctionId, brokerToken }) => {
  const modalContext = useModalContext();

  const openBidModal = (isNew, bid) => modalContext.open(
    <BidModal bid={bid} isNew={isNew} auctionId={auctionId} brokerToken={brokerToken} />, true);
  const openDeleteBidModal = ({ bidId }) => modalContext.open(
    <DeleteBidModal bidId={bidId} auctionId={auctionId} brokerToken={brokerToken} />, true);

  const columns = [
    {
      Header: "Cost Quote",
      accessor: "costQuote",
      Cell: ({ value }) => formatCurrencyOrFallback(value),
      minWidth: 120
    },
    {
      Header: "Transit Time (Days)",
      accessor: "transitTime",
      width: 200
    },
    {
      Header: "Carrier",
      accessor: "carrier"
    },
    {
      id: "edits",
      sortable: false,
      className: "align-right",
      Cell: ({ original, index }) => <>
        <MetaButton icon={{ src: Trash, alt: "Edit Bid" }}
          variant="no-background"
          onClick={() => openDeleteBidModal(original)}
          data-testid={`bids-broker-delete-button-${original.bidId}`} />
        <MetaButton icon={{ src: EditSquare, alt: "Delete Bid" }}
          variant="no-background"
          onClick={() => openBidModal(false, original)}
          data-testid={`bids-broker-edit-button-${original.bidId}`} />
      </>
    }
  ];

  return (
    <MetaCard title="Your Bids"
      className={classNames("bids-broker", { "no-padding": bids?.length > 0 })}
      data-testid="bids-broker"
      spine={bids?.length > 0 ? "" : "primary"}
      button={{ text: "Submit Bid", variant: "primary", size: "m", onClick: () => openBidModal(true), "data-testid": "bids-broker-submit-bid-button" }}
    >
      {bids?.length > 0 ?
        <MetaTable columns={columns} data={bids} showPagination={false} sortable={false}
          getProps={() => ({ "data-testid": "broker-bids-table" })} />
        : <p>You haven't submitted any bids yet.</p>}
    </MetaCard>
  );
};

BidsBroker.propTypes = {
  bids: PropTypes.arrayOf(
    PropTypes.shape({
      bidId: PropTypes.number,
      broker: PropTypes.string,
      costQuote: PropTypes.number,
      transitTime: PropTypes.string,
      carrier: PropTypes.string,
    }),
  ),
  brokerToken: PropTypes.string,
};

export default BidsBroker;
