import React from "react";
import PropTypes from "prop-types";

export const ErrorDisplay = ({ error, resetErrorBoundary }) => {
  return (
    <div>
      {error.message}
    </div>
  )
};

ErrorDisplay.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  resetErrorBoundary: PropTypes.func,
};
