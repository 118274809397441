import React from "react";
import PropTypes from "prop-types";
import { MetaTabs, MetaTab, MetaCard } from "@spreetail/meta-components";

import { LoadDetails } from "../routing-details-load";
import { Logistics } from "../routing-details-logistics";
import { PickupContactsDetails } from "../routing-details-pickup-contacts";

export const RoutingDetails = ({ logistics, load, pickUpNotes, pickUpContacts }) => {

  const tabs = [
    {
      label: "Load & Logistics",
      id: "load-log",
      className: "",
      content:
        <>
          <Logistics {...logistics} />
          <LoadDetails {...load} />
        </>,
      show: true
    },
    {
      label: "Pick-Up Notes",
      id: "pickUpNotes",
      className: "routing-details-notes",
      content: <p>{pickUpNotes || "No notes."}</p>,
      show: (pickUpNotes && pickUpNotes.length > 0)
    },
    {
      label: "Pick-Up Contacts",
      id: "pickUpContacts",
      className: "",
      content: <PickupContactsDetails pickupContacts={pickUpContacts} />,
      show: true
    },
  ];

  return (
    <MetaCard title="Routing Details" data-testid="routing-details" className="routing">
      <MetaTabs>
        {tabs.filter(({ show }) => show).map(({ label, id, className, content }) =>
          <MetaTab key={id} label={label} className={className} id={id}>{content}</MetaTab>
        )}
      </MetaTabs>
    </MetaCard>
  );
};

RoutingDetails.propTypes = {
  logistics: PropTypes.shape({

  }),
  load: PropTypes.shape({

  }),
  pickUpNotes: PropTypes.string,
  pickUpContacts: PropTypes.array
};